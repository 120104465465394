$blue: #043CA7;
$borderPadding: 40px;
$contentPadding: $borderPadding;

.wrapper {
  //min-width: 400px;
  width: 100%;

  .video-wrapper{
    transform: translate(-50%);
    position: absolute;
    left: 50%;
    margin-top: 80px;
    overflow: hidden;

  }

}

.border{
  margin: $borderPadding;
  border: 1px solid $blue;
}

.content{
  position: relative;

  padding: 500px $contentPadding $contentPadding $contentPadding;

  &-left{
    text-align: left;
  }

  &-right{

    margin-top: $borderPadding;




    &-wrapper{
      display: flex;
      justify-content: space-between;
    }

    .heart{

    }

    .content-text{
      white-space: nowrap;
    }
  }

  &-text{
      text-decoration: underline;
      text-decoration-color: $blue;
      text-underline-offset: 5px;
      color: $blue;
      font-family: acumin-pro, sans-serif;
      font-weight: 600;
      font-style: normal;
  }

  .contact-links{

    a{
      display: block;
      text-align: right;
    }
  }
}

@media only screen and (min-width: 1500px) {
 .content-left{
   span{
     display: block;
   }
 }
}

@media all and (min-width: 600px) {

  .border{
    margin: 0;
    position: absolute;
    top: $borderPadding;
    bottom: $borderPadding;
    left: $borderPadding;
    right: $borderPadding;
  }

  .wrapper {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    .video-wrapper{
      margin-top: -($contentPadding * 2);
    }
  }

  .content {
    position: absolute;
    bottom: $contentPadding;
    left: $contentPadding;
    right: $contentPadding;
    display: flex;
    justify-content: space-between;
    padding: 0;

    &-left{
      padding-right: $borderPadding;
    }

    &-right{
      margin-top: 0;

      &-wrapper{
        display: flex;
        justify-content: flex-end;
      }
      .heart{
        margin-left:$borderPadding;
      }

      .content-text{
        white-space: nowrap;
      }
    }

  }
}


//-------
//$blue: #043CA7;
//$borderPadding: 40px;
//$contentPadding: $borderPadding * 2;
//
//.wrapper {
//  width: 100%;
//  height: 100vh;
//  display: flex;
//  align-items: center;
//  justify-content: center;
//}
//
//.border{
//  position: absolute;
//  top: $borderPadding;
//  bottom: $borderPadding;
//  left: $borderPadding;
//  right: $borderPadding;
//  border: 1px solid $blue;
//}
//
//.content{
//  position: absolute;
//  bottom: $contentPadding;
//  left: $contentPadding;
//  right: $contentPadding;
//  display: flex;
//  justify-content: space-between;
//
//  &-left{
//    text-align: left;
//    padding-right: $borderPadding;
//  }
//
//  &-right{
//
//    &-wrapper{
//      display: flex;
//      justify-content: flex-end;
//    }
//    .heart{
//      margin-left:$borderPadding;
//    }
//
//    .content-text{
//      white-space: nowrap;
//    }
//  }
//
//  &-text{
//    text-decoration: underline;
//    text-decoration-color: $blue;
//    color: $blue;
//    font-family: acumin-pro, sans-serif;
//    font-weight: 600;
//    font-style: normal;
//  }
//
//  .contact-links{
//    a{
//      display: block;
//    }
//  }
//}
//
//@media only screen and (min-width: 1500px) {
//  .content-left{
//    span{
//      display: block;
//    }
//  }
//}
//
//@media only screen and (max-width: 600px) {
//  body {
//    background-color: lightblue;
//  }
//}
